import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Link, Stack, Tabs } from '@mui/material';
import i18next from 'i18next';
import { omit, pick, sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DintilhacText } from 'src/components/client/prejudiceFormComponents/DintilhacText';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { CalculsFormCalendrierDepense } from 'src/constants/calculs';
import { prejudiceBaseDefaultValues } from 'src/constants/prejudice/defaultValues';
import {
  getDefaultMinAndMaxDate,
  validationObjectCapitalisation,
  validationSchemaPrejudiceBase,
  validationSchemaPrejudiceFormCapitalisationOrRenteBase,
  validationSchemaRentes,
  validationSchemaTiersPayeursCapitalisationObject,
  validationSchemaTiersPayeursCapitalisationParTiersPayeurObject,
} from 'src/constants/prejudice/validation';
import {
  getCapitalisationBaremes,
  getShouldNotDisplayCapitalisation,
} from 'src/helpers/prejudices/capitalisation';
import { isPrejudiceVictimeIndirecte } from 'src/helpers/prejudices/prejudice';
import { dateString } from 'src/helpers/yup';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import * as yup from 'yup';
import {
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierDepenseCapitalisationRow,
  PrejudiceType,
} from '../../../types/prejudice.type';
import { PrejudiceContainer } from '../PrejudiceContainer';
import { Chiffrage } from '../prejudiceFormComponents/Chiffrage';
import {
  defaultTableDepenseRow,
  TableDepenses,
} from '../prejudiceFormComponents/DepensesSante/TableDepenses';
import { TotalDepensesCapitalisation } from '../prejudiceFormComponents/TotalDepensesCapitalisation';
import { PrejudiceFormProps } from '../PrejudiceFormProps';
import { PrejudiceTab } from '../PrejudiceTab';
import { SavePrejudiceButton } from '../SavePrejudiceButton';
import { validationSchemaCalendrierDepenseRow } from './validationSchemaCalendrierDepenseRow';

const validationSchemaCalendrierDepenseCapitalisationRow = ({
  tiersPayeurs,
  dateConsolidation,
  isVictimeIndirecte,
  prejudiceType,
  procedure,
  victime,
}: {
  tiersPayeurs: string[];
  dateConsolidation?: Date;
  isVictimeIndirecte?: boolean;
  prejudiceType: PrejudiceType;
  procedure: Procedure;
  victime: Victime;
}): yup.ObjectSchema<PrejudiceFormCalendrierDepenseCapitalisationRow> => {
  const { minDate, maxDate } = getDefaultMinAndMaxDate({
    procedure,
    victime,
    prejudiceType,
  });
  const { minDate: minDateJustificatif, maxDate: maxDateJustificatif } =
    CalculsFormCalendrierDepense.getMinAndMaxDateJustificatif({
      procedure,
      isCalculCapitalisation: true,
    });
  return validationSchemaCalendrierDepenseRow({
    tiersPayeurs,
    dateConsolidation,
    isVictimeIndirecte,
    prejudiceType,
    procedure,
    victime,
  }).shape({
    dateDebut: dateString()
      .nullable()
      .defined()
      .minDate(undefined, minDate.message, minDate.date)
      .maxDate(undefined, maxDate.message, maxDate.date)
      .required(),
    dateFin: dateString()
      .nullable()
      .defined()
      .minDate(
        'dateDebut',
        i18next.t('validation.prejudices.dates.minDateDebut') || '',
      )
      .maxDate(undefined, maxDate.message, maxDate.date),
    dateJustificatif: dateString()
      .nullable()
      .defined()
      .minDate(undefined, minDateJustificatif.message, minDateJustificatif.date)
      .maxDate(
        undefined,
        maxDateJustificatif.message,
        maxDateJustificatif.date,
      ),
    renouvellementMaintenance: yup
      .number()
      .optional()
      .nullable()
      .when('frequenceMontant', {
        is: 'viagere',
        then: (schema) => schema.required().min(1),
      }),
    capitalisation: yup
      .object(omit(validationObjectCapitalisation, ['bareme']))
      .shape({
        dateAttribution: dateString().nullable().optional(),
      }),
    montantUnitaireAnnualise: yup.number().optional(),
    capitalisationTiersPayeurs: yup
      .object()
      .shape(
        omit(validationSchemaTiersPayeursCapitalisationObject(), [
          'montantCapitalise',
        ]),
      ),
    rentes: validationSchemaRentes.optional(),
  });
};

export const validationSchemaCalendrierDepenseCapitalisation = ({
  tiersPayeurs,
  dateConsolidation,
  isVictimeIndirecte,
  prejudiceType,
  procedure,
  victime,
}: {
  tiersPayeurs: string[];
  dateConsolidation?: Date;
  isVictimeIndirecte?: boolean;
  prejudiceType: PrejudiceType;
  procedure: Procedure;
  victime: Victime;
}): yup.ObjectSchema<PrejudiceFormCalendrierDepenseCapitalisation> => {
  return validationSchemaPrejudiceBase
    .concat(validationSchemaPrejudiceFormCapitalisationOrRenteBase)
    .shape({
      rows: yup.array().defined().of(
        validationSchemaCalendrierDepenseCapitalisationRow({
          tiersPayeurs,
          dateConsolidation,
          isVictimeIndirecte,
          prejudiceType,
          procedure,
          victime,
        }),
      ),
      revalorisationCoefficientsType: yup
        .string()
        .oneOf(['mensuel', 'annuel'])
        .required(),
      capitalisation: yup.object(
        pick(validationObjectCapitalisation, ['bareme', 'montantCapitalise']),
      ),
      capitalisationTiersPayeurs: yup.object({
        parTiersPayeur: yup
          .array()
          .defined()
          .of(
            yup.object(
              pick(
                validationSchemaTiersPayeursCapitalisationParTiersPayeurObject,
                ['tiersPayeur', 'montantCapitalise'],
              ),
            ),
          ),
        montantCapitalise: yup.number().required(),
      }),
    });
};

export const calendrierDepenseCapitalisationDefaultValues = ({
  procedure,
  values,
  victime,
  prejudiceType,
}: {
  procedure: Procedure;
  values?: PrejudiceFormCalendrierDepenseCapitalisation;
  victime: Victime;
  prejudiceType: PrejudiceType;
}): PrejudiceFormCalendrierDepenseCapitalisation => ({
  ...prejudiceBaseDefaultValues({
    prejudiceType,
    values,
  }),
  notes: values?.notes || '',
  rows:
    sortBy(values?.rows || [], (row) => row.dateDebut)?.map((row) => ({
      ...defaultTableDepenseRow({
        tiersPayeurs: procedure.tiersPayeurs,
        type: row.type,
        isCalculCapitalisation: false,
        procedure,
        victime,
      }),
      ...row,
      priseEnChargeTiersPayeurs: procedure.tiersPayeurs.map((tiersPayeur) => ({
        tiersPayeur,
        montant:
          (Array.isArray(row.priseEnChargeTiersPayeurs)
            ? row.priseEnChargeTiersPayeurs
            : []
          )?.find(
            (priseEnChargeTiersPayeur) =>
              priseEnChargeTiersPayeur.tiersPayeur === tiersPayeur,
          )?.montant || 0,
      })),
      priseEnChargeTiersPayeursProratises: procedure.tiersPayeurs.map(
        (tiersPayeur) => ({
          tiersPayeur,
          montant:
            (Array.isArray(row.priseEnChargeTiersPayeursProratises)
              ? row.priseEnChargeTiersPayeursProratises
              : []
            ).find(
              (priseEnChargeTiersPayeur) =>
                priseEnChargeTiersPayeur.tiersPayeur === tiersPayeur,
            )?.montant || 0,
        }),
      ),
      capitalisation: {
        isLastArrerageViager: row.capitalisation?.isLastArrerageViager || false,
        ageDernierArrerage: row.capitalisation?.ageDernierArrerage || null,
        coefficient: row.capitalisation?.coefficient || 0,
        montantCapitalise: row.capitalisation?.montantCapitalise || 0,
        dateAttribution:
          row.capitalisation?.dateAttribution || row.dateDebut || null,
      },
      capitalisationTiersPayeurs: {
        parTiersPayeur: procedure.tiersPayeurs.map((tiersPayeur) => ({
          tiersPayeur,
          sommeACapitaliser: 0,
          coefficient: 0,
          montantCapitalise: 0,
          ...row.capitalisationTiersPayeurs?.parTiersPayeur?.find(
            (row) => row.tiersPayeur === tiersPayeur,
          ),
        })),
      },
    })) || [],
  capitalisation: {
    bareme:
      values?.capitalisation?.bareme ??
      procedure.baremes?.baremeCapitalisationId ??
      '',
    montantCapitalise: values?.capitalisation?.montantCapitalise || 0,
    enableCapitalisationDifferee:
      values?.capitalisation?.enableCapitalisationDifferee ?? true,
  },
  capitalisationTiersPayeurs: {
    parTiersPayeur: procedure.tiersPayeurs.map((tiersPayeur) => {
      const tiersPayeurValues =
        values?.capitalisationTiersPayeurs?.parTiersPayeur?.find(
          (row) => row.tiersPayeur === tiersPayeur,
        );
      return {
        tiersPayeur,
        montantCapitalise: 0,
        ...tiersPayeurValues,
      };
    }),
    montantCapitalise: 0,
  },
  revalorisationCoefficientsType:
    values?.revalorisationCoefficientsType || 'annuel',
  isRentesOption: values?.isRentesOption || false,
  rentes: {
    montant: values?.rentes?.montant || 0,
  },
});

type Props = PrejudiceFormProps<PrejudiceFormCalendrierDepenseCapitalisation>;

export const FormCalendrierDepenseCapitalisation: React.FC<Props> = ({
  prejudiceType,
  victime,
  procedure,
  values,
  monetaryErosions,
  baremes,
  dateLiquidation,
  dateConsolidation,
  dateDeces,
  allNumerosPieces,
  ...props
}) => {
  const [activeTab, setActiveTabs] = useState(0);

  const capitalisationBaremes = useMemo(
    () => getCapitalisationBaremes(baremes, victime.sexe),
    [baremes, victime.sexe],
  );
  const { control, ...useFormReturn } =
    useForm<PrejudiceFormCalendrierDepenseCapitalisation>({
      defaultValues: calendrierDepenseCapitalisationDefaultValues({
        procedure,
        values,
        victime,
        prejudiceType,
      }),
      resolver: yupResolver(
        validationSchemaCalendrierDepenseCapitalisation({
          tiersPayeurs: procedure.tiersPayeurs,
          isVictimeIndirecte: isPrejudiceVictimeIndirecte(prejudiceType),
          prejudiceType,
          dateConsolidation,
          procedure,
          victime,
        }),
      ),
    });
  const { t } = useTranslation();
  const shouldNotDisplayCapitalisation = getShouldNotDisplayCapitalisation({
    dateConsolidation,
    dateLiquidation,
    dateDeces,
  });

  const [showDintilhac, setShowDintilhac] = React.useState<boolean>(false);

  const hideLink = prejudiceType === 'FRAIS_DIVERS_FUTURS';

  return (
    <PrejudiceContainer<PrejudiceFormCalendrierDepenseCapitalisation>
      {...props}
      prejudiceType={prejudiceType}
      victime={victime}
      procedure={procedure}
      monetaryErosions={monetaryErosions}
      baremes={baremes}
      control={control}
      {...useFormReturn}
      renderPrejudice={() => (
        <Stack direction="column" spacing={4}>
          {hideLink && (
            <Link
              href="https://www.impots.gouv.fr/simulateur-bareme-kilometrique"
              target="_blank"
              rel="noopener noreferrer"
            >
              {'https://www.impots.gouv.fr/simulateur-bareme-kilometrique'}
            </Link>
          )}
          <TextFieldForm
            control={control}
            name="notes"
            label={t('prejudice.fields.notes.label')}
            placeholder={t('prejudice.fields.notes.placeholder') || ''}
            InputLabelProps={{ shrink: true }}
            // TODO : uncomment when dintilhac text for DSF is read is ready
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <DintilhacButton onClick={() => setShowDintilhac(true)} />
            //     </InputAdornment>
            //   ),
            // }}
            multiline
            maxRows={4}
            fullWidth
          />
          <DintilhacText
            open={showDintilhac}
            setOpen={setShowDintilhac}
            label={t('prejudice.fields.dintilhac.label')}
            content={i18next.t(
              `prejudice.prejudicesTypes.${prejudiceType}.introduction`,
              {
                defaultValue: '',
              },
            )}
          />
          <Tabs value={activeTab} onChange={(_, value) => setActiveTabs(value)}>
            <PrejudiceTab
              label={t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.tabs.depenses.title',
                {
                  prejudiceType,
                },
              )}
              value={0}
              sx={{ maxWidth: '500px' }}
              isError={!!Object.keys(useFormReturn.formState.errors)?.length}
            />
            <PrejudiceTab
              label={t(
                'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.tabs.recapitulatif.title',
                {
                  prejudiceType,
                },
              )}
              value={1}
            />
          </Tabs>
          <Stack sx={{ display: activeTab === 0 ? '' : 'none' }}>
            <TableDepenses
              control={control}
              tiersPayeurs={procedure.tiersPayeurs}
              isCalculCapitalisation
              monetaryErosions={monetaryErosions}
              dateLiquidation={dateLiquidation}
              dateConsolidation={dateConsolidation}
              procedure={procedure}
              victime={victime}
              prejudiceType={prejudiceType}
              allNumerosPieces={allNumerosPieces}
              baremesCapitalisation={capitalisationBaremes}
              shouldNotDisplayCapitalisation={shouldNotDisplayCapitalisation}
              dateDeces={dateDeces}
            />
          </Stack>
          <Stack sx={{ display: activeTab === 1 ? '' : 'none' }}>
            <TotalDepensesCapitalisation
              control={control}
              tiersPayeurs={procedure.tiersPayeurs}
              partResponsabilite={procedure.partResponsabilite}
              dateLiquidation={dateLiquidation}
              monetaryErosions={monetaryErosions}
              victime={victime}
              baremesCapitalisation={capitalisationBaremes}
              dateConsolidation={dateConsolidation}
              dateDeces={dateDeces}
            />
          </Stack>
          <Grid container>
            <Grid item xs={4}>
              <Chiffrage control={control} />
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SavePrejudiceButton victime={victime} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Stack>
      )}
    />
  );
};
